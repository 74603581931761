import { render } from "./EventRowActionsDropdown.vue?vue&type=template&id=3ba9c33b"
import script from "./EventRowActionsDropdown.vue?vue&type=script&lang=ts"
export * from "./EventRowActionsDropdown.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QBtnDropdown,QList,QItem,QItemSection,QIcon,QItemLabel});qInstall(script, 'directives', {ClosePopup});
