<template>
  <q-dialog v-model="logModal.state.show" :persistent="false">
    <EventLogModal
      v-if="logModal.state.show"
      :event-id="eventId"
      :event-uuid="eventUuid"
    />
  </q-dialog>
  <q-btn-dropdown
    @click.stop
    size="sm"
    flat
    rounded
    padding="5px"
    color="grey-7"
    dropdown-icon="mdi-dots-vertical"
    content-class="no-border-radius"
  >
    <q-list padding>
      <q-item
        clickable
        v-close-popup
        v-for="action in actions"
        :key="action.label"
        @click="action.action"
      >
        <q-item-section avatar>
          <q-icon size="xs" color="grey-8" :name="action.icon" />
        </q-item-section>

        <q-item-section no-wrap>
          <q-item-label>{{ action.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  provide,
} from 'vue'
import { EventTableItem } from '@/types/event'
import { useModal, UseModal } from '@/composable/useModal'
import { useEvent } from '@/composable/useEvent'
import { useProfile } from '@/composable/useProfile'
import EventLogModal from '@/components/event/EventLogModal.vue'
import { Log } from '@/types/log'

export interface EventProp extends EventTableItem {
  end?: Date | null
  reason?: {
    reason1: string
  }
}

interface EventRowAction {
  label: string
  icon: string
  permission: string
  action: () => void
  predicate?: () => boolean
}

export default defineComponent({
  name: 'EventRowActionsDropdown',

  components: {
    EventLogModal,
  },

  props: {
    event: {
      type: Object as PropType<EventProp>,
      required: true,
    },
    skipFetchEvent: {
      type: Boolean,
      default: () => false,
    },
  },

  setup(props) {
    const { can } = useProfile()
    const { fetch, fetchLog, data, getPenaltyAssessment } = useEvent()
    const logModal = useModal()
    provide('log-modal', logModal)
    const formModal = inject<UseModal<Log[]>>('form-modal')
    const deleteModal = inject<UseModal<string>>('delete-modal')
    const closeModal = inject<UseModal<EventProp>>('close-modal')
    const openModal = inject<UseModal<EventProp>>('open-modal')
    const penaltyAssessmentModal = inject<UseModal<EventProp>>(
      'penalty-assessment-modal'
    )
    const actions: ComputedRef<EventRowAction[]> = computed(() => {
      const actionsStart: EventRowAction[] = [
        {
          label: 'Redigera',
          icon: 'mdi-circle-edit-outline',
          permission: 'deviationManagement.event.update',
          action: () => {
            formModal?.openModal({
              mode: 'update',
              cb: async () => {
                if (props.skipFetchEvent) return Promise.resolve()
                if (!props?.event?.uuid) return Promise.resolve()
                await fetch(props.event.uuid)
                return Promise.resolve()
              },
            })
          },
        },
      ]

      if (props.event?.open || !props.event?.end) {
        actionsStart.push({
          label: 'Avsluta händelsen',
          icon: 'mdi-check-underline-circle-outline',
          permission: 'deviationManagement.event.update',
          action: () => {
            closeModal?.openModal({
              data: props.event,
              cb: async () => {
                await fetch(props.event.uuid)
                return Promise.resolve()
              },
            })
          },
        })
      }

      if (props.event?.end) {
        actionsStart.push({
          label: 'Aktivera händelsen',
          icon: 'mdi-check-underline-circle-outline',
          permission: 'deviationManagement.event.update',
          action: () => {
            openModal?.openModal({
              data: props.event,
              cb: async () => {
                await fetch(props.event.uuid)
                return Promise.resolve()
              },
            })
          },
        })
      }

      const actionsEnd: EventRowAction[] = [
        {
          label: 'Vitesbedömning',
          icon: 'mdi-alpha-v-box-outline',
          permission: 'event.penaltyAssessment',
          predicate: () => {
            return [props.event.reason?.reason1, props.event.reason1].includes(
              'Fordonsskada'
            )
          },
          action: () => {
            penaltyAssessmentModal?.openModal({
              data: props.event,
              cb: async () => {
                await getPenaltyAssessment(props.event.uuid)
                return Promise.resolve()
              },
            })
          },
        },
        {
          label: 'Visa logg',
          icon: 'mdi-view-list',
          permission: 'deviationManagement.event.list',
          action: () => {
            logModal?.openModal({
              cb: async () => {
                if (!props?.event?.uuid) return Promise.resolve()
                await fetchLog(props.event.uuid)
                return Promise.resolve()
              },
            })
          },
        },

        // {
        //   label: 'Skriv ut',
        //   icon: 'mdi-printer',
        //   permission: 'deviationManagement.event.list',
        //   action: () => {
        //     console.log('print')
        //   },
        // },
        {
          label: 'Ta bort',
          icon: 'mdi-delete-forever',
          permission: 'deviationManagement.event.delete',
          action: () => {
            deleteModal?.openModal({
              data: props?.event?.uuid,
            })
          },
        },
      ]

      return [...actionsStart, ...actionsEnd].filter(
        ({ permission, predicate }) =>
          can(permission) && typeof predicate !== 'undefined'
            ? predicate()
            : true
      )
    })

    const eventId = computed(() => {
      return props?.event?.id || data?.value?.id
    })

    const eventUuid = computed(() => {
      return props?.event?.uuid || data?.value?.uuid
    })

    return { actions, logModal, eventId, eventUuid }
  },
})
</script>
